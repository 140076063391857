import './style.scss'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/account'
import ActivityModal from '@/components/ActivityModal'
import PlanModal from '@/components/PlanModal'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import SettingBanner from './components/Banner'
import NewUserPackage from './components/NewUserPackage'
import PointDetail from './components/PointDetail'
import UserDetail from './components/UserDetail'
import VipCompany from './components/VipCompany'

const Setting: FC = () => {
  const { userPackage } = UserStore
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [logs, setLogs] = useState<any[]>([])
  const [apiInfo, setApiInfo] = useState<any>({})
  const [activityModalOpen, setActivityModalOpen] = useState(false)

  useEffect(() => {
    eventTracking('PersonalCenterPage')
    getAllCreditLogs()

    UserStore.checkUserPackage()
  }, [])

  useEffect(() => {
    getApiInfo()
  }, [userPackage])

  const getAllCreditLogs = async () => {
    const res = await Api.getAllCreditLogs()
    setLogs(res.list || [])
  }

  const getApiInfo = async () => {
    if (userPackage?.current_membership_level >= 100) {
      const res = await Api.getApiInfo()
      setApiInfo(res || {})
    }
  }

  const onSub = () => {
    setPlanModalOpen(true)
  }

  const onPaySuccess = async () => {
    getAllCreditLogs()
    await UserStore.updateUserPackage()
    getApiInfo()
  }

  const showActivity = () => {
    setActivityModalOpen(true)
  }

  return (
    <div className="page-setting">
      {userPackage && (
        <>
          <div className="page-setting-user">
            <SettingBanner activityBannerClick={showActivity} />

            <UserDetail onPaySuccess={onPaySuccess} onSub={onSub} />

            {userPackage.current_membership_level >= 100 && <VipCompany apiInfo={apiInfo} onSave={getApiInfo} />}

            <NewUserPackage onPaySuccess={onPaySuccess} onSub={onSub} />

            <PointDetail list={logs} />
          </div>
        </>
      )}

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            onPaySuccess()
          }, 300)
        }
      />

      <ActivityModal
        open={activityModalOpen}
        onCancel={() => {
          onPaySuccess()
          setActivityModalOpen(false)
        }}
      />
    </div>
  )
}

export default memo(Setting)
