import { Modal } from 'antd'
import dayjs from 'dayjs'
import resso from 'resso'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import { getUserInfo } from './api/account'
import { showPlanModal } from './libs/util'

export interface IUserPackage {
  membership_credits: number
  current_membership_credits: number
  current_membership_package_title: string
  membership_expire_time: number
  membership_expired: boolean
  permanent_credits: number
  free_credits: number
  current_membership_level: number
}

export interface IProduct {
  title: string
  duration_type: number
  id: number
  unit_credits: number
  units: number
  total_price: number
  discounted_unit_price: number
  original_unit_price: number
  level: number
  status: number
  tag: string
  sale_opening_time?: number
}

export const UserStore = resso({
  phone: '',
  uid: '',
  device_id: localStorage.getItem('di') || '',
  userPackage: undefined as unknown as IUserPackage,
  showCompleted: false,
  setUserToken: (d: any) => {
    localStorage.setItem('token', d.token)
    getUserInfo()
  },
  setUserInfo: (d: any) => {
    UserStore.phone = d.phone
    UserStore.uid = d.uid
  },
  getToken: () => {
    return localStorage.getItem('token')
  },
  loginOut: () => {
    localStorage.removeItem('token')
    location.replace('/login')
  },
  // 检查套餐是否过期或者即将过期
  checkUserPackage: async () => {
    const res: IUserPackage = await Api.getUserPackage()
    UserStore.userPackage = res

    if (res?.membership_expire_time) {
      const diff = dayjs(res.membership_expire_time * 1000).diff(dayjs(Date.now()), 'second') / 3600
      const vipToExpried = !res.membership_expired && diff > 0 && diff <= 24
      const vipExpried = res.membership_expired
      const expriedTime = dayjs(res.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')

      if (!sessionStorage.getItem('vipToExpried') && vipToExpried) {
        sessionStorage.setItem('vipToExpried', 'true')
        return Modal.confirm({
          title: '会员即将到期提醒',
          okText: '续费/升级',
          onOk: () => {
            showPlanModal()
          },
          content: `您的会员将于${expriedTime}到期，届时您将无法使用会员权益。为了不影响您正常创作，建议您提前续费或升级会员`
        })
      } else if (!sessionStorage.getItem('vipExpried') && vipExpried) {
        sessionStorage.setItem('vipExpried', 'true')
        return Modal.confirm({
          title: '会员到期提醒',
          okText: '续费/升级',
          onOk: () => {
            showPlanModal()
          },
          content: `您的会员已于${expriedTime}到期，您无法再使用会员权益，建议您续费或升级会员`
        })
      }
    }

    return false
  },
  updateUserPackage: async () => {
    const res: IUserPackage = await Api.getUserPackage()
    UserStore.userPackage = res
    return res
  },
  getQaCompleted: async () => {
    const res = await homeApi.getQuestionCompleted()
    UserStore.showCompleted = !res.completed
  }
})
